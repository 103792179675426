import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const cache = new InMemoryCache();

const tokenValue = localStorage.getItem("token") ? localStorage.getItem("token") : "";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  headers: {
    authorization: `Bearer ${tokenValue}`,
  },
});

const errorHandler = onError(({ graphQLErrors }) => {
  const hasUnauthorized =
    graphQLErrors &&
    graphQLErrors.find((error) => {
      const { message } = error;
      return message.includes("Unauthorized");
    });
  if (hasUnauthorized) {
    alert("Your session is expired.Please login again.");
    localStorage.clear();
    window.location.href = "/";
  }
});

const apolloclient = new ApolloClient({
  link: from([errorHandler, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

export default apolloclient;
