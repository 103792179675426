// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

// Material Kit 2 React examples

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  padding: 30,
}));
function Information() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Item>
              <Typography gutterBottom variant="h3" component="div" sx={{ textTransform: "none" }}>
                Tution Fee
              </Typography>
              <Typography
                variant="h6"
                color="text.dark"
                sx={{ textTransform: "none", lineHeight: 2 }}
              >
                For Merit Students - 45000 per year
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Item>
              {" "}
              <Typography gutterBottom variant="h3" component="div" sx={{ textTransform: "none" }}>
                Tution Fee
              </Typography>
              <Typography
                variant="h6"
                color="text.dark"
                sx={{ textTransform: "none", lineHeight: 2 }}
              >
                For Management Students - 60000 per year
              </Typography>{" "}
            </Item>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
