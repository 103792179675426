// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";
import AcademicCalendar from "../../../../../assets/images/academic-calendar.pdf";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Information() {
  return (
    <MKBox component="section" py={5} sx={{ backgroundColor: "dark" }}>
      <Container sx={{ mt: 2, mb: 6 }}>
        <Grid container spacing={2}>
          <iframe
            src={AcademicCalendar}
            width="100%"
            height="1000px" // Set height for a visible area
            style={{ border: "none" }}
            title="Academic Calendar 2024-25"
            scrolling="yes" // Enable scrolling
          ></iframe>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
