// @mui material components
import Container from "@mui/material/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import MKButton from "components/MKButton";
import Slider from "react-slick";
import TopMenu from "../../components/TopMenu";
// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";
import { useNavigate } from "react-router-dom";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar/index";
import DefaultFooter from "../../examples/Footers/DefaultFooter/index";

// Material Kit 2 React components
import MKBox from "../../components/MKBox/index";

// Presentation page components
import "../Presentation/index.css";

// Images
import bgImage from "../../assets/images/banner-3.webp";
import bgAbout from "../../assets/images/bg-about.webp";
import arrow from "../../assets/images/arrow.png";
import admission from "../../assets/images/admission.webp";
import courseBg from "../../assets/images/coursesbg.webp";
import guru from "../../assets/images/guru.png";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { NEWS } from "../../graphql/query/news";
import { GALLERIES } from "../../graphql/query/gallery";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const SliderText = styled("div")({
  fontSize: "15px",
  marginTop: "10px",
  fontWeight: 400,
});
const Body = styled("div")({
  backgroundColor: "#fff9fe",
});
const AboutSection = styled("div")({
  backgroundColor: "#fff9fe",
});
const CourseSection = styled("div")({
  backgroundColor: "#fff9fe",
});
const NewsBox = styled("div")({
  borderRadius: 5,
});
function Presentation() {
  const [offset] = React.useState(0);
  const [newsDatas, setNewsDatas] = React.useState([]);
  const [galleryDatas, setGalleryDatas] = React.useState([]);
  const { loading, error, data } = useQuery(NEWS, {
    variables: {
      newsFilter: {
        status: null,
        limit: 4,
        offset,
        orderDirection: "desc",
        orderColumn: "id",
      },
    },
  });
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/contact-us`;
    navigate(path);
  };

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    vertical: true,
    centerMode: true,
    lazyLoad: true,
  };
  const {
    loading: galleryloading,
    error: galleryerror,
    data: gallerydata,
  } = useQuery(GALLERIES, {
    variables: {
      filterInput: {
        status: null,
        limit: 10,
        offset,
        sortOrder: "desc",
        sortColumn: "id",
      },
    },
  });
  React.useEffect(
    () => {
      if (loading === false) {
        if (error) {
          setNewsDatas([]);
        } else {
          setNewsDatas(data.news.news);
        }
      }
      if (galleryloading === false) {
        if (galleryerror) {
          setGalleryDatas([]);
        } else {
          setGalleryDatas(gallerydata.getGalleries.galleries);
        }
      }
    },
    [data, loading, error],
    [gallerydata, galleryloading, galleryerror]
  );
  const footerRedirect = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Body>
        {/* <MKBox variant="gradient" bgColor="white" shadow="sm" py={0.25}>
          <Container>
            <TopMenu />
          </Container>
          <hr />
          <DefaultNavbar routes={routes} transparent relative dark center />
        </MKBox> */}

        <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
          <DefaultNavbar routes={routes} transparent relative light center />
        </MKBox>
        <MKBox>
          <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
        </MKBox>

        <MKBox
          minHeight={{ xs: "30vh", md: "85vh" }}
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={4}>
              <div>
                <h1 className="banner-head pb-3 ">
                  A TO Z of Teaching <br />
                  and Learning
                </h1>
                <p className="banner-sub" style={{ fontStyle: "italic" }}>
                  {" "}
                  " A Terrain Of Professionalism "{" "}
                </p>
              </div>

              <MKButton className="btn-white" color="warning" onClick={routeChange} sx={{ mt: 3 }}>
                Contact Us
              </MKButton>
            </Grid>
          </Container>
        </MKBox>
        <Container sx={{ mt: 6, mb: 6 }}>
          <div className="row bg-mob-about d-block d-sm-none">
            <div className="col-md-6 mb-4">
              <div className="card custom-card">
                <div className="card-body newsBox p-3">
                  <h2 className="clgName">
                    sahodaran ayyappan memorial
                    <br /> college of education
                  </h2>
                  <p className="esta">established in 1995</p>
                  <h5 className="affiliation">
                    Accredited with A grade by <span>NAAC</span> in 2014
                  </h5>
                  <h5 className="affiliation">
                    Affiliated to <span>mahatma gandhi university</span> Kottayam
                  </h5>
                  <div className="row address-text">
                    <div className="col-md-6">Email: samcoed@gmail.com</div>
                    <div className="col-md-6">Phone: 0484-2791218</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card news-card">
                <div className="card-header">
                  <h2 className="news-header">NEWS</h2>
                </div>
                {loading ? (
                  <center>
                    <CircularProgress />
                  </center>
                ) : (
                  <div className="card-body newsBox p-c-5">
                    {newsDatas.length ? (
                      <Slider {...settings}>
                        {newsDatas.map((item) => (
                          <SliderText className="slider-text">
                            {item.title}{" "}
                            <a className="news-btn" href="/news">
                              {" "}
                              Read more{" "}
                            </a>
                          </SliderText>
                        ))}
                      </Slider>
                    ) : (
                      <h3>No Data to Display</h3>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <AboutSection
            sx={{
              backgroundImage: `url(${bgAbout})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
              height: "650px",
            }}
            className="d-none d-sm-block"
          >
            <div className="row">
              <div className="col-md-6">
                <NewsBox
                  className="CollegeDetails"
                  sx={{
                    position: "absolute",
                    minHeight: 310,
                    right: "5%",
                    top: "0",
                  }}
                >
                  <div className="card custom-card">
                    <div className="card-body newsBox p-3">
                      <h2 className="clgName">
                        sahodaran ayyappan memorial
                        <br /> college of education
                      </h2>
                      <p className="esta">established in 1995</p>
                      <h5 className="affiliation">
                        Accredited with A grade by <span>NAAC</span> in 2004
                      </h5>
                      <h5 className="affiliation">
                        Affiliated to <span>mahatma gandhi university</span> Kottayam
                      </h5>
                      <div className="row address-text">
                        <div className="col-md-6">Email: samcoed@gmail.com</div>
                        <div className="col-md-6">Phone: 0484-2791218</div>
                      </div>
                    </div>
                  </div>
                </NewsBox>
              </div>
              <div className="col-md-6">
                <NewsBox
                  className="newsBox"
                  sx={{ position: "absolute", minHeight: 310, right: "3%", bottom: "-10%" }}
                >
                  <div className="card news-card">
                    <div className="card-header">
                      <h2 className="news-header">NEWS</h2>
                    </div>
                    {loading ? (
                      <center>
                        <CircularProgress />
                      </center>
                    ) : (
                      <div className="card-body newsBox p-5">
                        {newsDatas.length ? (
                          <Slider {...settings}>
                            {newsDatas.map((item) => (
                              <SliderText>
                                {item.title}{" "}
                                <a className="news-btn" href="/news">
                                  {" "}
                                  Read more{" "}
                                </a>
                              </SliderText>
                            ))}
                          </Slider>
                        ) : (
                          <h3>No Data to Display</h3>
                        )}
                      </div>
                    )}
                  </div>
                </NewsBox>
              </div>
              <div className="col-md-5 about-footer">
                <h1 className="about-h1">About Us</h1>
                <h5 className="affiliation">
                  Sahodaran Ayyappan Memorial College of Education is a part of Sree Narayana group
                  of educational institutions managed by S.N.D.P. Yogam branch No. 1103 with a
                  mission to ensure equal educational opportunities and to propagate quality
                  education.
                </h5>
              </div>
            </div>
          </AboutSection>
        </Container>
        <div className="container pt-4">
          <div className="row d-flex align-items-center" id="vision">
            <div className="col-md-2">
              <img src={arrow} alt="" className="img-fluid" />
            </div>
            <div className="col-md-5">
              <h1 className="vision-head">Motto</h1>
              <p>
                The motto of the college is, 'Awaken by Enlightenment' . This is the exhortation of
                Sree Narayana Guru to the downtrodden people of the world to awaken to their true
                potential through education.
              </p>
            </div>
            <div className="col-md-5">
              <h1 className="vision-head">goals</h1>
              <p>
                <ul>
                  <li>Dissemination of quality teacher education.</li>
                  <li>
                    Unique concern for the down trodden sections of society as reflected in the
                    motto ‘Awaken‘
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="container my-5" id="admission">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-4">
              <h1>
                admission<br></br> enquiry
              </h1>
              <p>
                The duration of <strong>B.Ed</strong> programme will be two year with four
                semesters, having a break after one year during April-May. There will be a minimum
                of 550 hrs distributed over 100 working days in each semester .
              </p>
              {/* <MKButton color="warning" onClick={routeChange}>
                Contact Us
              </MKButton> */}
            </div>
            <div className="col-md-6 mt-3">
              <img src={admission} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <CourseSection
          sx={{
            backgroundImage: `url(${courseBg})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            overflow: "hidden",
            py: 5,
          }}
        >
          <div className="row px-5 pt-3 pb-3 " id="course">
            <div className="col-md-3">
              <h1 className=" courses-offered">
                Courses
                <br /> Offered
              </h1>
            </div>
          </div>
          <div className="row px-5 d-flex align-items-center" id="courses">
            <div className="col-md-3">
              <div className="course-box">
                <ul>
                  <li>mathematics</li>
                  <li>natural science</li>
                  <li>physical science</li>
                  <li>social science</li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="course-box">
                <ul>
                  <li>hindi</li>
                  <li>english</li>
                  <li>commerce</li>
                  <li>malayalam</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <h1 className=" courses-offered">bachelor of education</h1>
              <div className="btn btn-light">
                <a href="https://ncte.gov.in/website/index.aspx" target="_blank">
                  Go To NCTE Website
                </a>
              </div>
            </div>
          </div>
        </CourseSection>
        <CourseSection
          sx={{
            background:
              "linear-gradient(180deg, rgba(255, 121, 0, 0) -12.22%, rgba(255, 121, 0, 0.4) 100%)",
            display: "grid",
          }}
        >
          <div className="container px-3 py-5">
            <h1 className="guru pb-3">
              Progress through Education & <br />
              Strengthen through Organization
            </h1>
            <h3 className="guru">By Sree Narayana Guru</h3>
            <div className="guruimg">
              <img src={guru} />
            </div>
            <p className="guru-text">
              Sahodaran Ayyappan Memorial College of Education is a part of Sree Narayana group of
              educational institutions managed by S.N.D.P. Yogam branch No. 1103 with a mission to
              ensure equal educational opportunities and to propagate quality education. The College
              started to function during the year 1995-96 getting directives from the Mahatma Gandhi
              University, Kottayam. The college has been accredited with 'A' Grade by National
              Assessment and Accreditation Council (NAAC) in 2004. Our Institution is the first self
              financing institution that was credited with 'A' grade by NAAC. The M.Ed. Department
              in the college started to function during the year 2006-07, getting directives from
              Mahatma Gandhi University, Kottayam. The college is situated at Puthenkavu, near the
              Ernakulam - Kottayam Highway , adjacent to Sree Narayana Vallabha Temple, which
              sanctifies the noble mission of education. The B.Ed Programme focuses on eight
              optional subjects viz Malayalam, Hindi, English, Mathematics, Natural Science,
              Physical Science, Social Science and Commerce. The college seeks to impart quality
              teacher education by adopting various ideological and technological advancements. The
              student teachers are given the opportunity to broaden their vision of education
              through educational seminars at National and State level, debates, discussions and
              symposiums. The college provides opportunities for cultural and physical activities
              for a balanced development of teacher personality. For nurturing the sports talent of
              student teacher the college organizes an Annual Throw Ball Tournament for the
              Sahodaran Ayyappan Memorial Ever Rolling Trophy with the participation of all colleges
              of Teacher Education affiliated to M.G. University Kottayam. The College organize an
              inter B.Ed. collegiate art festival SAM FEST and a quiz competition for all B.Ed.
              Colleges under M.G. University, Kottayam. Besides these major inter B.Ed. collegiate
              competitions, the college organizes a variety of cultural, literal and sports
              activities.
            </p>
          </div>
        </CourseSection>
        {/* <Container sx={{ mt: 6, mb: 6 }}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={6}>
              <Item sx={{ mb: 2 }}>News</Item>
              <Card sx={{ overflow: "hidden", p: 3, minHeight: 300 }}>
                <CardContent>
                  <Slider {...settings}>
                    {newsDatas.map((item) => (
                      <SliderText>{item.title}</SliderText>
                    ))}
                  </Slider>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Item>College Details</Item>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minHeight: 275, py: 5, px: 5 }} alignItems="center">
                <CardContent>
                  <Typography variant="h3" component="div" sx={{ textAlign: "center", pb: 2 }}>
                    Motto{" "}
                  </Typography>
                  <Typography variant="body2" textAlign="justify">
                    The motto of the college is, &apos;Awaken by Enlightment&apos;. This is the
                    exhortation of Sree Narayana Guru to the downtrodden people of the world to
                    awaken to their true potential through education.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <MKButton variant="gradient" color="warning">
                    Read More
                  </MKButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minHeight: 300, py: 5, px: 5 }} alignItems="center">
                <CardContent>
                  <Typography variant="h3" component="div" sx={{ textAlign: "center", pb: 2 }}>
                    Goals{" "}
                  </Typography>
                  <Typography variant="body2" textAlign="justify">
                    Dissemination of quality teacher education. Unique concern for the down trodden
                    sections of society as reflected in the motto ‘Awaken
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <MKButton variant="gradient" color="warning">
                    Read More
                  </MKButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minHeight: 275, py: 5, px: 5 }} alignItems="center">
                <CardContent>
                  <Typography variant="h3" component="div" sx={{ textAlign: "center", pb: 2 }}>
                    About Us{" "}
                  </Typography>
                  <Typography variant="body2" textAlign="justify">
                    Sahodaran Ayyappan Memorial College of Education is a part of Sree Narayana
                    group of educational institutions managed by S.N.D.P. Yogam branch No. 1103 with
                    a mission to ensure equal educational opportunities and to propagate quality
                    education.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <MKButton variant="gradient" color="warning">
                    Read More
                  </MKButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ minHeight: 275, py: 5, px: 5 }} alignItems="center">
                <CardContent>
                  <Typography variant="h3" component="div" sx={{ textAlign: "center", pb: 2 }}>
                    Admission Enquiry{" "}
                  </Typography>
                  <Typography variant="body2" textAlign="justify">
                    The duration of B.Ed programme will be two year with four semesters, having a
                    break after one year on April-May. There will be a minimum of 550 hrs
                    distributed over 100 working days in each semester .
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <MKButton variant="gradient" color="warning">
                    Read More
                  </MKButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <h3>Courses Offered By S.A.M College Of Education</h3>
            </Grid>
            <Grid item xs={6}>
              <Item>Bachelor of Education</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Go to NCTE Website</Item>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ minWidth: 275, p: 5 }}>
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    Progress through Education & Strengthen through Organization -By Sree Narayana
                    Guru{" "}
                  </Typography>
                  <Typography variant="body2" textAlign="justify">
                    Sahodaran Ayyappan Memorial College of Education is a part of Sree Narayana
                    group of educational institutions managed by S.N.D.P. Yogam branch No. 1103 with
                    a mission to ensure equal educational opportunities and to propagate quality
                    education. The College started to function during the year 1995-96 getting
                    directives from the Mahatma Gandhi University, Kottayam. The college has been
                    accredited with &apos;A&apos; Grade by National Assessment and Accreditation
                    Council (NAAC) in 2004. Our Institution is the first self financing institution
                    that was credited with &apos;A&apos; grade by NAAC. The M.Ed. Department in
                    the college started to function during the year 2006-07, getting directives from
                    Mahatma Gandhi University, Kottayarn. The college is situated near the Ernakulam
                    - Kottayam Highway at Puthenkavu , adjacent to Sree Narayana Vallabha Temple,
                    which sanctifies the noble mission of education. The B.Ed Programme focuses on
                    eight optional subjects viz Malayalam, Hindi, English, Mathematics, Natural
                    Science, Physical Science, Social Science and Commerce. The M.Ed. course is
                    offered in Malayalam, English, Mathematics, Science, Social Science and
                    Commerce. The college seeks to impart quality teacher education by adopting
                    various ideological and technological advancements. The student teachers are
                    given the opportunity to broaden their vision of education through educational
                    seminars at National and State level, debates, discussions and symposiums. The
                    college provides opportunities for cultural and physical activities for a
                    balanced development of teacher personality. For nurturing the sports talent of
                    student teacher the college organizes an Annual Throw Ball Tournament for
                    Sahodaran Ayyappan Memorial Ever Rolling Trophy with the participation of all
                    colleges of Teacher Education affiliated to M.G. University Kottayam. The
                    College organize an intercollegiate art festival SAM FEST and a quiz competition
                    for all B.Ed. Colleges under M.G. University, Kottayam. Besides these major
                    inter-collegiate competitions, the college organizes a variety of cultural,
                    literal and sports activities.
                  </Typography>
                </CardContent>
              </Card>{" "}
            </Grid>
            <Grid item xs={8}>
              <Item>Gallery</Item>
            </Grid>
          </Grid>
        </Container> */}
      </Body>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
