// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 25,
}));
function Information() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Process
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.dark"
                      sx={{ textTransform: "none", lineHeight: 2 }}
                    >
                      <ul className="academics-list">
                        <li>
                          {" "}
                          Admission in the merit seats for B.Ed. course will be as per the
                          centralised allotment procedure by the Govt. of Kerala.{" "}
                        </li>
                        <li>
                          {" "}
                          A student who has enlisted on the roll and attended any part of the term
                          will have to pay the whole fees if he / she discontinues the course.{" "}
                        </li>
                        <li>
                          {" "}
                          No student shall be admitted unless he / she pays fees as per rules and
                          produce all the certificates of qualifications mentioned below:{" "}
                          <Typography
                            variant="body2"
                            color="text.dark"
                            sx={{
                              textTransform: "none",
                              paddingLeft: 5,
                              lineHeight: 2,
                              fontSize: 16,
                            }}
                          >
                            <ul className="academics-list">
                              <li>
                                Transfer Certificate from the Educational Institution last attended
                              </li>
                              <li>
                                S.S.L.C. Book. Degree Certificate and Marklists of Qualifying
                                Examinations
                              </li>
                              <li>Course and Conduct certificate</li>
                              <li>
                                Migration Certificate (in the case of graduates from other
                                Universities){" "}
                              </li>{" "}
                              <li> Community Certificate in case of backward communities. </li>{" "}
                              <li>
                                {" "}
                                Eligibility certificate (in case of student from Universities
                                outside Kerala ).{" "}
                              </li>
                            </ul>
                          </Typography>
                        </li>
                      </ul>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Fees
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.dark"
                      sx={{ textTransform: "none", lineHeight: 2 }}
                    >
                      <ul className="academics-list">
                        <li>
                          Every student shall pay the First installment of fees including Special
                          fees Prescribed by the University and Government at the time of admission.
                        </li>
                        <li>
                          Every Student shall pay the second installment of fee on the date
                          announced by the authorities.
                        </li>
                        <li>
                          Receipts signed by the Accountant will be issued on realization of all
                          kinds of fees and other dues.
                        </li>
                        <li> Fees once paid will not be refunded at any circumstances. </li>
                      </ul>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Issue Of Certificates
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.dark"
                      sx={{ textTransform: "none", lineHeight: 2 }}
                    >
                      Transfer Certificates shall be issued only after the payment of all dues and
                      the clearance of all liabilities with the college. A student, past or present,
                      applying for certificate of Transfer. Character, age, Mark, Identification
                      etc. shall do so to the Principal. Ordinarily, notice of not less than one day
                      is necessary for the issue of a certificate. No certificate, will be sent by
                      post unless. the charges for transmission by registered post are remitted.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
