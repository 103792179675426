// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "../../../../../components/MKTypography";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";
// Images
import commerce from "../../../../../assets/images/course/commerce.png";
import mathematics from "../../../../../assets/images/course/mathematics.png";
import english from "../../../../../assets/images/course/english.png";
import naturalScience from "../../../../../assets/images/course/natural-science.png";
import hindi from "../../../../../assets/images/course/hindi.png";
import physicalScience from "../../../../../assets/images/course/physical-science.png";
import socialScience from "../../../../../assets/images/course/social-science.png";
import malayalam from "../../../../../assets/images/course/malayalam.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Information() {
  return (
    <MKBox component="section" py={5} sx={{ backgroundColor: "dark" }}>
      <Container sx={{ mt: 2, mb: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 className="text-center sam_heading">General Aspects of B.Ed programme</h2>
            <MKTypography variant="body2" color="text">
              <ul className="academics-list">
                <li>
                  Sahodaran Ayyappan Memorial college of Education is a self financing college
                  affiliated to Mahatma Gandhi University ,Kottayam. The college offers 2 year (4
                  Semesters) B.Ed Programme.
                </li>
                <li>
                  The admission towards the course is in accordance with the M G university and NCTE
                  norms.
                </li>
                <li>The annual intake for the B.Ed course is 100 students ( 2 units).</li>
                <li>The college offers B.Ed courses in the following optional subjects.</li>
              </ul>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={commerce} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Commerce
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={mathematics} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Mathematics{" "}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={english} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    English
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={naturalScience} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Natural Science
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={hindi} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Hindi
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={physicalScience} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Physical Science
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={malayalam} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Malayalam
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} lg={2} sx={{ opacity: 0.25 }}>
                    {" "}
                    <img src={socialScience} width="60" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    Social Science
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
